import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Redirection = ({ children }) => (
  <div>
    <span>返回</span>
    <Link to="/">{children}</Link>
  </div>
)

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>找不到頁面</h1>
    <p>這個路徑並沒有對應的頁面</p>
    <Redirection>首頁</Redirection>
  </Layout>
)

export default NotFoundPage
